.contact {
    width: 100%;
    padding: 15rem 0;
    align-items: center;
}

.contact-container {
    max-width: 800px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 400px);
    grid-gap: 40px;
}

.contact h1 {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 3rem;
    color: #0c488d;
}

.left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
}

.right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    margin-top: 1rem;
}

.location {
    display: flex;
    margin-bottom: 0.8rem;
}

.location p {
    line-height: 30px;
}

h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}

p {
    font-size: 1.2rem;
    margin: 0.5rem 0;
}

@media screen and (max-width: 769px) {
    .contact {
        position: relative;
    }

    .left,
    .right {
        padding: 0 3rem;
    }

    .contact-container {
        grid-template-columns: 1fr;
    }
}