.mask-background {
    width: 100%;
    height: 100vh;
    position: relative;
}

.background-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.mask-background::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.7;
}