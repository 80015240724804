.footer {
    width: 100%;
    padding: 1px 0;
    align-items: center;
    background-color: rgba(27, 234, 146, 0.1);

    margin: auto;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
}

.footer p {
    font-size: 0.9rem;
}

@media screen and (max-width: 769px) {
    .footer {
        position: relative;
    }

    .footer p {
        font-size: 0.7rem;
    }

    .footer-container {
        grid-template-columns: 1fr;
        display: inline;
    }
}