.logo-img {
    width: 20%;
    height: auto;
}

.home {
    height: 100%;
    width: 100%;
}

.home .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.home .content h1 {
    font-size: 3rem;
    padding: 1rem 0 0rem;
    color: #0c488d;
}

.home .content p {
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.6rem 0 1.5rem;
}

.content .btn {
    margin: 1rem 0.2rem;
}

@media screen and (max-width: 769px) {
    .logo-img {
        width: 50%;
        height: auto;
    }

    .home .content h1 {
        font-size: 2rem;
    }


    .home .content p {
        font-size: 1.4rem;
    }
}