.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 80px;
    /* box-shadow: 0 5px 15px rgb(0, 0, 0, 0.04); */

    padding: 3rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}

.nav-bg {
    background-color: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li {
    list-style: none;
    padding: 0 20px;
    position: relative;
}

#navbar li button {
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    background: transparent;
    border: none;
}

#navbar li button:hover,
#navbar li button.active{
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: #0c488d;
}

#navbar li button:hover::after,
#navbar li button.active::after{
    content: "";
    width: 30%;
    height: 2px;
    background: #0c488d;
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile {
    display: none;
}

#mobile i {
    align-items: center;
}

@media screen and (max-width: 769px) {
    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 95px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.95);
        box-shadow: 0 40px 60px rgba(255, 255, 255, 0.9);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navbar.active{ 
        right: 0px;
    }

    #navbar li {
        margin-bottom: 25px;
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }
}
















/* .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}

.header-bg {
    background-color: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
}

.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu li a {
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburguer {
    display: none;
}

@media screen and (max-width: 1040px) {
    .nav-menu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu li a {
        font-size: 2rem;
    }

    .hamburguer {
        display: initial;
    }
} */