.information {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15rem;
}

.information h1 {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 3rem;
    color: #0c488d;
}

.information .content {
    text-align: left;
    max-width: 800px;
    position: relative;
}

.information .content p {
    margin: 1.5rem 0;
}

/* .information-img {
    width: 100%;
    height: 80%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.information-mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50;
    left: 0;
    background-color: #fff;
    opacity: 0.8;
} */

.content .agendar {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 2.5rem auto;
}

@media screen and (max-width: 769px) {
    .information {
        padding: 5rem;
        position: relative;
    }

    .information .content {
        text-align: left;
    }

    .information h1 {
        font-size: 2rem;
    }
}