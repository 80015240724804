@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}

body {
  background: white;
  height: 100%;
}

h1, h4, p, a {
  color: #333333;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #0c488d;
  color: white;
  border: 1px solid #333333;
  font-size: 600;
  cursor: pointer;
}

.btn-light {
  background: transparent;
  color: #0c488d;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.3);
  color: #0c488d;
  transition: 0.3s;
}

